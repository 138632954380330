import React from "react";
import ContactForm from "../ContactForm";
import "./style.scss";
const ContactArea = ({ className, id }) => {
  return (
    <div className={className} id={id}>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='text-center contactInfo mb-5'>
              <h2>Contact Us</h2>
              <h6>Great stories always begin by a first meeting.</h6>
            </div>
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactArea;
