import React from "react";
import "./style.scss";

const services = [
  { icon: "advice.svg", subtitle: "Expert", title: "Advice" },
  { icon: "euros.svg", subtitle: "Smart", title: "Money" },
  {
    icon: "network.svg",
    subtitle: "Join Our",
    title: "Network",
  },
];
const Service = (props) => {
  return (
    <div className={props.className}>
      <div className='container'>
        <div className='row'>
          {services.map((service, i) => (
            <div key={i} className='col-lg-4 col-md-6'>
              <div className='serviceItem'>
                <div className='serviceIcon'>
                  <i className={service.icon}></i>
                  <img src={require("../../images/icons/" + service.icon)} />
                </div>
                <div className='serviceText'>
                  <span>{service.subtitle}</span>
                  <h3>{service.title}</h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Service;
