import React, { Fragment } from "react";
import HeaderBotton from "../../../components/HeaderBottom";
import HeroSlider from "../../../components/HeroSlider";
import Service from "../../../components/Service";
import About from "../../../components/About";
import ContactArea from "../../../components/ContactArea";
import FooterArea from "../../../components/FooterArea";
// images
import aboutImg from "../../../images/about/about.jpg";
import historyImg from "../../../images/about/history.jpg";
import valuesImg from "../../../images/about/values.jpg";
import lookImg from "../../../images/about/look.jpg";
import strategyImg from "../../../images/about/strategy.jpg";

const strategyText = [
  {
    text:
      "YBI invests primarily in entrepreneurial projects, growth capital opportunities and buy-and-build projects. YBI can invest in both early stage and mature companies. Therefore, we have a capacity to invest in :",
  },
  {
    text: "— Seed projects i.e. in very young and disruptive companies",
  },
  {
    text: "— In series A companies to support their development",
  },
  {
    text: "— And in growth capital  to support traditional companies.",
  },
  {
    text:
      "— In specific situations, YBI can also lead round table with other private investors in order to increase its investment capacity.",
  },
];

const lookText = [
  {
    text:
      "YBI supports positive entrepreneurs i.e. entrepreneurs who want to make big moves through tech innovation. Indeed, we believe in the power of new technology to help company to strenghen their leadership.",
  },
  {
    text:
      "YBI provides therefore not only financing for its participations but also strategic advice to hit their targets. To illustrate, YBI shares its best practices on different themes such as human resources, Business Development, communication, and strategic marketing.",
  },
];

const aboutText = [
  {
    text:
      "YBI mainly supports startups with innovative solutions. However, good ideas and creativity are not enough for YBI. Indeed, the core strategy of YBI is focused on financing overmotivated entrepreneurs whose ambition is to disrupt their market and succeed where others thought it was impossible.",
  },
  {
    text:
      "YBI has the conviction that each success is not the result of any one individual, but rather the collective efforts of everyone who worked on the transaction.",
  },
  {
    text:
      "Therefore, YBI not only brings capital to companies bu also shares its expertise in developing fast growing tech companies and answers to key questions: How to deal with hyper-growth ? How can you optimise your distribution ? How to optimise your business model ?",
  },
];

const historyText = [
  {
    text:
      "Yves Bardiani is an entrepreneur who created his investment holding  to support startups and fast growing small-and-mid companies experiencing the same difficulties: lack of organizational structure and weak balance sheets. Such pains can be healed in order to achieve their goals.",
  },
  {
    text:
      "YB wants to bring the frame and the support for such companies to meet success !",
  },
  {
    text:
      "YB has worked in various companies and developed skills in HR, audit and advisory. Above all, he has an a very strong expertise in e-business issues such as ecommerce, fintech. ",
  },
];

const valuesText = [
  {
    text:
      "Our values are « passion », « hard work », and “ambition”. Our brand, “YBI”, sums up our approach :",
  },
  {
    text:
      "Y : everything always begins by a simple question “why”. We consider that questioning the reality is the best way to start new projects. All in all, why can’t we imagine that any market can be disrupted ?",
  },
  {
    text:
      "B : Business is in the heart of our activity but ethics is our backbone. We aim to make increase business activity of our companies with a sustainable strategy.",
  },
  {
    text:
      "I :Interaction is the key of the success. We are day-to-day partners with our companies and we appreciate to be challenged as much as needed.",
  },
];

const heroSliders = [
  {
    images: "slideWrapperOne",
    title: "We Are Specialized In Innovative Projects",
    subTitle: "Particularly In The Tech Industry",
    text:
      "YBI is an independent private equity firm founded by Yves Bardiani, investor and entrepreneur",
    button: "Contact us",
  },
];

const HomePageOne = () => {
  return (
    <Fragment>
      <header className='headerArea'>
        <HeaderBotton className='headerBottomArea' />
      </header>
      <HeroSlider sliders={heroSliders} className='heroSliderArea' />
      <Service className='serviceArea' />
      <About
        id='About'
        className='aboutArea'
        title='About Us'
        images={aboutImg}
        pragraphs={aboutText}
      />
      <About
        id='History'
        className='ourServiceArea aboutArea'
        title='Our History'
        subTitle=''
        images={historyImg}
        orderLast='order-last'
        pragraphs={historyText}
      />
      <About
        id='Values'
        className='aboutArea'
        title='Our Values'
        images={valuesImg}
        pragraphs={valuesText}
      />
      <About
        id='Look'
        className='ourServiceArea aboutArea'
        title='What We Look For'
        images={lookImg}
        orderLast='order-last'
        pragraphs={lookText}
      />
      <About
        id='Strategy'
        className='aboutArea'
        title='Strategy'
        images={strategyImg}
        pragraphs={strategyText}
      />
      <ContactArea id='Contact' className='contactArea' />
      <FooterArea />
    </Fragment>
  );
};
export default HomePageOne;
