import React from "react";
import { Formik, Field, Form } from "formik";

import "./style.scss";

const ContactForm = () => (
  <Formik
    initialValues={{
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      message: "",
    }}
    onSubmit={(values, { setStatus, resetForm }) => {
      fetch("https://api.formik.com/submit/YBI/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      resetForm({});
      setStatus({ success: true });
    }}
    render={() => (
      <Form className='contactForm'>
        <div className='row'>
          <div className='col-sm-6 col-12'>
            <div className='formInput'>
              <Field
                placeholder='First Name'
                name='firstname'
                className='form-control'
              />
            </div>
          </div>
          <div className='col-sm-6 col-12'>
            <div className='formInput'>
              <Field
                placeholder='Last Name'
                name='lastname'
                className='form-control'
              />
            </div>
          </div>
          <div className='col-sm-6 col-12'>
            <div className='formInput'>
              <Field
                placeholder='Your Email'
                name='email'
                className='form-control'
              />
            </div>
          </div>
          <div className='col-sm-6 col-12'>
            <div className='formInput'>
              <Field
                placeholder='Your Phone'
                name='phone'
                className='form-control'
              />
            </div>
          </div>
          <div className='col-12'>
            <div className='formInput'>
              <Field
                placeholder='Your Message'
                name='message'
                className='form-control'
                component='textarea'
              />
            </div>
          </div>
        </div>

        <button type='submit'>Submit</button>
      </Form>
    )}
  />
);

export default ContactForm;
