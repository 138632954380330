import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../../images/logo/logo.png";
import "./style.scss";

const HeaderBottom = (props) => {
  const [responsive, setResponsive] = useState(false);
  const responsiveHandler = () => {
    setResponsive(!responsive);
  };
  return (
    <div className={props.className}>
      <div className='container'>
        <div className='headerBottomMainWrapper'>
          <div className='row'>
            <div className='col-lg-3 col-md-4 col-sm-6 col-8'>
              <div className='logo'>
                <NavLink to='/'>
                  <img src={logo} alt='' />
                </NavLink>
              </div>
            </div>
            <div
              className={
                responsive
                  ? "col-lg-9 col-md-8 responsiveWrapper active"
                  : "col-lg-9 col-md-8 responsiveWrapper"
              }
            >
              <ul className='mainMenuWrap'>
                <li>
                  <a href='#About'>About</a>
                </li>
                <li>
                  <a href='#History'>History</a>
                </li>
                <li>
                  <a href='#Values'>Our Values</a>
                </li>
                <li>
                  <a href='#Strategy'>Strategy</a>
                </li>

                <li>
                  <a href='#Contact'>Contact</a>
                </li>
              </ul>
            </div>
            <div className='col-lg-1 col-md-2 col-sm-6 col-4'>
              <div className='searchMenuWrapper'>
                <div onClick={responsiveHandler} className='responsiveTrigger'>
                  <span className='first'></span>
                  <span className='second'></span>
                  <span className='third'></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HeaderBottom;
