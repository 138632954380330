import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo/logo.png";
import "./style.scss";
const footerLinks = [
  {
    title: "Contact Us",
    menus: [
      { name: "Phone: +44 74 88 844923" },
      { name: "Email: y.bardiani@protonmail.com" },
    ],
  },
];

const FooterArea = () => {
  return (
    <footer className='footerArea'>
      <div className='footerTopArea'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-6'>
              <div className='footerLogo'>
                <Link to='/'>
                  <img src={logo} alt='' />
                </Link>
                <p>
                  YBI is an independent private equity firm founded by Yves
                  Bardiani, investor and entrepreneur.
                </p>
              </div>
            </div>
            {footerLinks.map((menu, i) => (
              <div key={i} className='col-sm-6'>
                <div className='footerWrap'>
                  <h3>{menu.title}</h3>
                  <ul>
                    {menu.menus.map((item, i) => (
                      <li key={i}>
                        {item.route ? (
                          <Link to={`/${item.route}`}>{item.name}</Link>
                        ) : (
                          `${item.name}`
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='footerBottomArea'>
        <div className='container'>
          <div className='footerBottomContent'>
            <div className='row'>
              <div className='text-center col-12'>
                <span>© 2020 YBI. All rights reserved</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default FooterArea;
